import React from "react";
import './Loading.css'
import {Preloader} from "react-materialize";

class Loading extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="Loading center-align m-50">
                <Preloader size="big" color="orange"/>
                {this.props.message && (<p className="text-font-medium text-color-primary">{this.props.message}</p>)}
            </div>
        );
    }
}

export default Loading;