import React from 'react';
import './HomePage.css';
import ExamAPI from "../../api/examapi";
import {BrowserRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import ScrollToTop from "../common/ScrollToTop/ScrollToTop";
import ServerError from "./content/ServerError/ServerError";
import errorMessages from "../../api/ServerErrorMessages";
import Loading from "./content/Loading/Loading";
import ExamContent from "./content/ExamRow/ExamContent/ExamContent";
import ExamQuestions from "./content/ExamQuestion/ExamQuestions";
import NotFoundError from "./content/NotFoundError/NotFoundError";
import ExamCategories from "./content/ExamCateogries";

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            examCategories: [],
            fetching: true,
            errorType: null
        };

        this.handleOnErrorRetry = this.handleOnErrorRetry.bind(this);
    }

    handleOnErrorRetry() {
        this._getExams()
    }

    componentDidMount() {
        this._getExams()
    }

    async _getExams() {
        await ExamAPI.createAndInitialize();

        this.setState({fetching: true, errorType: null});
        ExamAPI.getInstance().getExams(
            (res) => (this.setState({examCategories: res.data.items, fetching: false})),
            (errorType) => (this.setState({errorType: errorType, fetching:false})));
    }

    render() {
        return (
            <Router>
                <ScrollToTop />
                {
                    this.state.errorType && (<ServerError
                            message={errorMessages[this.state.errorType]}
                            onRetry={this.handleOnErrorRetry}
                        />
                    )}
                {this.state.fetching && (<Loading message="Cargando..."/>)}

                <Switch>
                    <Route
                        exact
                        path={`${this.props.match.path}/`}
                        render={(props) =>
                            !this.state.errorType && !this.state.fetching && (
                                <ExamCategories {...props} examCategories={this.state.examCategories}/>
                            )}/>
                    <Route
                        exact
                        path={`${this.props.match.path}/categories/exams/:categoryId`}
                        render={(props) => !this.state.errorType && !this.state.fetching && (
                            <ExamContent {...props} categories={this.state.examCategories}/>
                        )}/>
                    <Route
                        exact
                        path={`${this.props.match.path}/exams/:examId/questions`}
                        render={(props) => !this.state.errorType && !this.state.fetching && (
                            <ExamQuestions {...props} />
                        )}/>
                    <Route
                        render={() => (!this.state.fetching && (
                            <NotFoundError/>
                        ))}/>
                </Switch>
            </Router>
        )
    }
}

export default withRouter(HomePage);