import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import App from "./App";
import Firebase, { FirebaseContext } from './firebase';


ReactDOM.render(
    <FirebaseContext.Provider value={Firebase.createInstance()}>
        <App />
    </FirebaseContext.Provider>,
    document.getElementById('root'));
registerServiceWorker();
