import "./QuestionNumber.css"
import React from "react";


class QuestionNumber extends React.Component {
    constructor(props) {
        super(props);
        this.elementRef = React.createRef();
        this.handleOnQuestionNumberClick = this.handleOnQuestionNumberClick.bind(this)
    }

    handleOnQuestionNumberClick(index) {
        this.props.onQuestionNumberSelected(index)
    }

    componentDidUpdate() {

        if (this.props.isQuestionSelected) {
            if (this.props.fromSwipe) {
                this.elementRef.current.scrollIntoViewIfNeeded(false)
            } else {
                this.elementRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                })
            }



        }
    }

    _getClassNameValue() {
        let baseClassNameValue = "circle waves-effect center-align ";
        const answerSelected = this.props.question.answers.filter((answer) => (answer.selected));
        const allAnswersCorrect = answerSelected.every(answer => answer.correct);
        const isQuestionAnswered = this.props.question.answered;
        const isQuestionSelected = this.props.isQuestionSelected;

        if (isQuestionSelected && !isQuestionAnswered && !this.props.examFinished) baseClassNameValue = baseClassNameValue + "teal-text bs-1-teal";
        else if (isQuestionSelected && !isQuestionAnswered && this.props.examFinished) baseClassNameValue = baseClassNameValue + " disabled ";
        else if (isQuestionSelected && isQuestionAnswered) baseClassNameValue = baseClassNameValue + "amber white-text bs-1-amber";
        else if (isQuestionAnswered && answerSelected.length === 0) baseClassNameValue = baseClassNameValue + "grey lighten-2 white-text bs-1-grey";
        else if (isQuestionAnswered && answerSelected.length > 0 && !allAnswersCorrect) baseClassNameValue = baseClassNameValue +  "red white-text bs-1-red";
        else if (isQuestionAnswered && answerSelected.length > 0 && allAnswersCorrect) baseClassNameValue = baseClassNameValue + "green white-text bs-1-green";
        else if (!isQuestionAnswered) baseClassNameValue = baseClassNameValue + "text-color-secondary bs-1-grey";

        return baseClassNameValue;
    }

    render() {

        const style = {
            height: this.props.height || 32,
            width: this.props.width || 32
        };

        return (
            <li
                className="QuestionNumber"
                ref={this.elementRef}>
                <span
                    className={this._getClassNameValue()}
                    style={style}
                    onClick={() => (this.handleOnQuestionNumberClick(this.props.number))}>
                    {this.props.number + 1}
                </span>
            </li>
        )
    }
}

export default QuestionNumber;