import app from 'firebase/app';
import 'firebase/analytics';
import 'firebase/remote-config';


const config = {
    apiKey: "AIzaSyBy6ekgyXLVoQGlKNV10V2VQ6AZ2nwBpy0",
    authDomain: "residentevil-79fca.firebaseapp.com",
    databaseURL: "https://residentevil-79fca.firebaseio.com",
    projectId: "residentevil-79fca",
    storageBucket: "residentevil-79fca.appspot.com",
    messagingSenderId: "158944908466",
    appId: "1:158944908466:web:722606035f01f4f1efe5ab",
    measurementId: "G-WWTWZCM1YE"
};

let instance = null;

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.analytics = app.analytics();
        this.remoteConfig = app.remoteConfig();
        this.fetched = false;
    }

    trackPageView(path) {
        this.analytics.logEvent(path);
    }


    async getRemoteString(key) {
        if (!this.fetched) {
            await this.remoteConfig.fetchAndActivate();
            this.fetched = true;
        }

        return this.remoteConfig.getString(key);
    }

    static createInstance() {
        instance = new Firebase();

        return instance;
    }

    static getInstance() {
        return instance;
    }


}

export default Firebase;
