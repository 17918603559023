import "./ExamQuestions.css"
import React from "react";
import ExamAPI from "../../../../api/examapi";
import Loading from "../Loading/Loading";
import ServerError from "../ServerError/ServerError";
import {Row, Col, Modal, Button, MediaBox} from "react-materialize"
import errorMessages from "../../../../api/ServerErrorMessages";
import QuestionNumberPagination from "./QuestionNumberPagination/QuestionNumberPagination";
import QuestionFlow from "./QuestionFlow/QuestionFlow.js"
import ExamResults from "./ExamResults/ExamResults";
import FeatureNotImplementedModal from "../../../common/FeatureNotImplementedModal/FeatureNotImplementedModal";
import { Swipeable } from 'react-swipeable'
import PageWrapper from "../../../common/PageWrapper/PageWrapper";
import {LocalStorage} from "../../../../helpers";
import TopicExamInfoModal from "../../../common/TopicExamInfoModal/TopicExamInfoModal";
import Firebase from "../../../../firebase";

const SAVED_EXAMS = `SAVED_EXAMS`

class ExamQuestions extends React.Component {
    constructor(props) {
        super(props);

        const resultId = new URLSearchParams(this.props.location.search).get("resultId")

        const exam = (LocalStorage.getItem(SAVED_EXAMS) || {})[resultId]

        this.state = {
            fetching: exam == null,
            showFinishExamConfirmationDialog: false,
            showFeatureNotImplemented: false,
            examFinished: exam != null && exam.finished,
            fromSwipe: false,
            exam: exam,
            currentQuestionNumber: 0,
            showInfoModal: false
        };

        this.handleOnErrorRetry = this.handleOnErrorRetry.bind(this);
        this.handleOnAnswerSelected = this.handleOnAnswerSelected.bind(this);
        this.handleOnQuestionNumberSelected = this.handleOnQuestionNumberSelected.bind(this);
        this.handleOnShowResultSelected = this.handleOnShowResultSelected.bind(this);
        this.handleCommentClick = this.handleCommentClick.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSwipe = this.handleSwipe.bind(this);
        this.handleFinishExam = this.handleFinishExam.bind(this);
        this.handleReviewExamClicked = this.handleReviewExamClicked.bind(this);
        this.handleInfoIconClick = this.handleInfoIconClick.bind(this);
        this.handleTopicExamInfoModalClose = this.handleTopicExamInfoModalClose.bind(this);
    }

    componentDidMount() {
        Firebase.getInstance().trackPageView('/app/question');
        const type = new URLSearchParams(this.props.location.search).get("type")
        if (this.state.exam == null) {
            console.log(`Fetching exams`)
            this._getExam(this.props.match.params.examId, type)
        }

    }

    handleOnShowResultSelected() {
        const questions = this.state.exam.questions;
        if (questions.filter((question) => (question.answered)).length === questions.length || this.state.examFinished) {
            //Show Results
            this.setState({showFinishExamConfirmationDialog: false, showResultDialog:true})
        } else {
            //Show Dialog
            this.setState({showFinishExamConfirmationDialog: true, showResultDialog:false})
        }
    }

    handleOnQuestionNumberSelected(questionNumber, fromSwipe) {
        this.setState({currentQuestionNumber: questionNumber, fromSwipe: fromSwipe != null ? fromSwipe: false})
    }

    handleOnErrorRetry() {
        this.setState({fetching:true, errorType: null});
        this._getExam(this.props.match.params.examId)
    }

    handleOnAnswerSelected(answer) {
        const currentQuestion = this.state.exam.questions[this.state.currentQuestionNumber];

        if (!currentQuestion.answered) {
            if (currentQuestion.type === "SINGLE") {
                answer.selected = true;
                currentQuestion.answered = true;
            }
            else if(currentQuestion.type === "MULTIPLE") {
                answer.selected = true;
                currentQuestion.answered = currentQuestion.answers.filter(answer => answer.correct).length === currentQuestion.answers.filter(answer => answer.selected).length
            }

            this.setState({exam: this.state.exam});
        }
    }

    handleCommentClick(event) {
        this.setState({showFeatureNotImplemented: true});
    }

    handleCloseModal(event) {
        this.setState({showFeatureNotImplemented: false});
    }

    async _getExam(examId, type){
        await ExamAPI.createAndInitialize();

        ExamAPI.getInstance().getExam(
            examId,
            type,
            (res) => (this.setState({exam: res.data, fetching: false, currentQuestionNumber: 0, startTime: new Date()})),
            (errorType) => (this.setState({errorType:errorType, fetching:false})))
    }

    _getAnswerClassNameValue(question, answer) {

        if (question.type === "MULTIPLE") {
            if (question.answered) {
                if (answer.correct) return "collection-item white-text active green disabled-click";
                else if (answer.selected) return "collection-item white-text active red disabled-click";
                else return "collection-item text-color-primary"
            } else {
                if ((answer.selected || this.state.examFinished) && answer.correct) return "collection-item white-text active green disabled-click";
                else if (answer.selected && !answer.correct) return "collection-item white-text active red disabled-click";
                else return "collection-item text-color-primary"
            }
        }

         else {
            if ((question.answered || this.state.examFinished) && answer.correct) return "collection-item white-text active green disabled-click";
            else if (question.answered && answer.selected && !answer.correct) return "collection-item white-text active red disabled-click";
            else if (question.answered) return "collection-item text-color-primary disabled-click";
            else return "collection-item text-color-primary"
        }
    }


    handleFinishExam() {
        const exam = this.state.exam;
        exam.startTime = this.state.startTime;
        exam.endTime = new Date();
        exam.finished = true;

        if (exam.type === "EXAM") {
            const savedExams = LocalStorage.getItem(SAVED_EXAMS) || {};
            savedExams[new Date().getTime()] = exam
            LocalStorage.setItem(SAVED_EXAMS, savedExams)
        }

        this.setState({exam, showFinishExamConfirmationDialog: false, showResultDialog: true, examFinished: true})
    }

    handleSwipe(data) {
        console.log(JSON.stringify(data));
        if (data.dir.toLowerCase() === "left" && this.state.currentQuestionNumber < this.state.exam.questions.length - 1) {
            this.handleOnQuestionNumberSelected(this.state.currentQuestionNumber + 1, true)
        }

        if (data.dir.toLowerCase() === "right" && this.state.currentQuestionNumber > 0) {
            this.handleOnQuestionNumberSelected(this.state.currentQuestionNumber - 1, true)
        }
    }

    handleReviewExamClicked(){
        this.setState({showResultDialog: false});
    }

    handleInfoIconClick() {
        this.setState({showInfoModal: true})
    }

    handleTopicExamInfoModalClose() {
        this.setState({showInfoModal: false})
    }


    render() {
        const question = !this.state.fetching && !this.state.errorType && this.state.exam.questions[this.state.currentQuestionNumber];
        const currentQuestion = this.state.exam ? this.state.exam.questions[this.state.currentQuestionNumber] : {}
        const examInfo = currentQuestion.exam_information;

        return (
            <PageWrapper title={this.state.exam != null ? this.state.exam.title : ''} subtitle={this.state.exam != null ? this.state.exam.campus_name : ''} handleBackButtonClicked={() => {window.history.back()}}>
                <div className={`ExamQuestion`}>
                    {this.state.fetching && <Loading message="Cargando..."/>}
                    {this.state.errorType && <ServerError message={errorMessages[this.state.errorType]} onRetry={this.handleOnErrorRetry}/>}
                    {!this.state.fetching && !this.state.errorType &&
                    <div className={`ExamQuestion-top`}>
                        <QuestionNumberPagination
                            questions={this.state.exam.questions}
                            currentQuestionNumber={this.state.currentQuestionNumber}
                            onQuestionNumberSelected={this.handleOnQuestionNumberSelected}
                            examFinished={this.state.examFinished}
                            fromSwipe={this.state.fromSwipe}
                        />
                        <Swipeable className={`ExamQuestion-swipe`} onSwiped={this.handleSwipe} deltaX={100} >
                            <div className="ExamQuestion-container mt-15">
                                <div className="ExamQuestion-main">
                                    {
                                        question.image_url && (
                                            <div className={`ExamQuestion-imageContainer mr-10 ml-10`}>
                                                <MediaBox
                                                    className={`ExamQuestion-mediabox`}
                                                    id="mediabox"
                                                    src={question.image_url}
                                                >
                                                </MediaBox>
                                            </div>
                                        )
                                    }

                                    <div className={`ExamQuestion-container white bs-1-grey mr-10 ml-10 mb-10 br-5`}>
                                        <Row className="QuestionText mb-0 p-5"><Col l={12} m={12} s={12} className="p-10">{question.question}</Col></Row>
                                        <hr className="bs-1_2-grey mt-0 mb-0 ml-10 mr-10"/>
                                        <Row className="valign-wrapper m-0 p-10">
                                            {
                                                question.topic && (
                                                    <div className="ExamQuestion-chip chip white-text text-font-smaller m-0" style={{backgroundColor: question.topic.color}}>{question.topic.description}</div>
                                                )
                                            }

                                            <Col l={10} m={10} s={8} className="right-align">
                                                <Button className="ExamQuestion-button ExamQuestion-comment waves-effect mr-5 mt-0 mb-0 ml-5 p-0" onClick={this.handleCommentClick}>

                                                    <img className={`p-0 m-0 valign-middle ${question.comment_size > 0 ? `ExamQuestion-commentImage--hidden` : `ExamQuestion-commentImage--visible`}`}
                                                         src={`/comment-bubble.svg`}
                                                         height={21}
                                                         width={21}/>

                                                    <img className={`p-0 m-0 valign-middle ${question.comment_size > 0 ? `ExamQuestion-commentImage--visible` : `ExamQuestion-commentImage--hidden`}`}
                                                         src={`/comment-bubble-orange.svg`}
                                                         height={22}
                                                         width={22}/>
                                                    {
                                                        question.comment_size ? (
                                                            <span className={`ExamQuestion-commentSize `}>{question.comment_size}</span>
                                                        ) : null
                                                    }

                                                </Button>
                                                {
                                                    this.state.exam.type === "TOPIC" && (
                                                        <Button className="ExamQuestion-button ExamQuestion-info waves-effect valign-wrapper mr-5 mt-0 mb-0 ml-5 p-0" onClick={this.handleInfoIconClick}>
                                                            <img className="p-0 m-0 valign-middle"
                                                                 src="/information.svg"
                                                                 height={20}
                                                                 width={20}/>
                                                        </Button>
                                                    )
                                                }

                                            </Col>
                                        </Row>
                                    </div>

                                </div>
                                <ul className="ExamQuestion-questions BackgroundContent AnswerContainer collection m-10">
                                    {question.answers.map((answer) => (
                                        <li key={answer.value} className={this._getAnswerClassNameValue(question, answer)} onClick={() => !this.state.examFinished && this.handleOnAnswerSelected(answer)}>{answer.description}</li>
                                    ))}
                                </ul>
                            </div>
                        </Swipeable>
                    </div>
                    }
                    {!this.state.fetching &&
                    <QuestionFlow
                        currentQuestionNumber={this.state.currentQuestionNumber}
                        questions={this.state.exam.questions}
                        onStepperSelected={this.handleOnQuestionNumberSelected}
                        onShowResultSelected={this.handleOnShowResultSelected}
                        examFinished={this.state.examFinished}
                    />
                    }
                    {this.state.showFinishExamConfirmationDialog &&
                    <Modal header="Finalizar Examen"
                           open={this.state.showFinishExamConfirmationDialog}
                           bottomSheet
                           modalOptions={{
                               dismissible: false
                           }}
                           actions={
                               [
                                   <Button
                                       modal="close"
                                       flat
                                       waves="orange"
                                       onClick={this.handleFinishExam}
                                       className="orange-text">
                                       Sí, Finalizar
                                   </Button>,
                                   <Button
                                       waves="orange"
                                       modal="close"
                                       flat
                                       className="grey-text"
                                       onClick={() => this.setState({showFinishExamConfirmationDialog: false})}>
                                       No
                                   </Button>]
                           }>
                        <p>
                            Todavía te quedan {this.state.exam.questions.filter((question) => (!question.answered)).length} preguntas por responder<br/>
                            ¿Estás seguro que deseas finalizar el examen y ver los resultados?
                        </p>
                    </Modal>
                    }
                    {this.state.showResultDialog &&
                    <Modal
                        className={`ExamQuestion-results-modal`}
                        bottomSheet={false}
                        fixedFooter={false}
                        open={this.state.showResultDialog}
                        actions={null}
                        modalOptions={{
                            dismissible: false
                        }}
                    >
                        <ExamResults exam={this.state.exam} handleReviewExamClicked={this.handleReviewExamClicked}/>
                    </Modal>
                    }

                    {
                        this.state.showFeatureNotImplemented && (
                            <FeatureNotImplementedModal
                                show={true}
                                onDismiss={this.handleCloseModal}
                            />
                        )
                    }

                    {
                        this.state.showInfoModal && examInfo && (
                            <TopicExamInfoModal
                                show={true}
                                onDismiss={this.handleTopicExamInfoModalClose}
                                examInformation={examInfo}
                            />
                        )
                    }

                </div>
            </PageWrapper>
        );
    }
}

export default ExamQuestions;