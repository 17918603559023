import React, { Component } from 'react';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import MainPage from "./components/main/MainPage";
import HomePageContainer from "./components/home/HomePageContainer";

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/">
                        <MainPage/>
                    </Route>
                    <Route path="/app">
                        <HomePageContainer />
                    </Route>
                </Switch>
            </Router>
        )
    }
}

export default App;
