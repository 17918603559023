import React from "react";
import "./NotFoundError.css"
import Firebase from "../../../../firebase";

class NotFoundError extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Firebase.getInstance().trackPageView('/not-found')
    }

    render() {
        return (
            <div className="NotFoundError center-align" >
                <img
                    src="/patient.svg"
                    alt="Not found"
                    height={120}
                    width={120}
                />
                {/*TODO: Internacionalizar esto*/}
                <h5>Página no encontrada</h5>
            </div>
        );
    }

}

export default NotFoundError;