import React from "react";
import {Link} from "react-router-dom";
import "./MainPage.scss";
import MCImage from "../common/MCImage";
import Firebase from "../../firebase";

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolled: false
        }
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        Firebase.getInstance().trackPageView('/');
        document.addEventListener(`scroll`, this.handleScroll);
    }

    handleScroll() {
        if (window.scrollY > 0) {
            this.setState({scrolled: true})
        } else {
            this.setState({scrolled: false})
        }
    }

    render() {
        return (
            <div className={`MainPage`}>
                <header className={`MainPage__header ${this.state.scrolled ? `MainPage__header--shadow` : ``}`}>
                    <div className={`MainPage__headerLeft`}>
                        <MCImage className={`MainPage__logo`} src={`/logo.svg`} alt={`logo`} />
                        <span className={`MainPage__brand`}>Medical Choice</span>
                    </div>
                    <Link to={`/app`} className={`MainPage__startButton MainPage__startButton--top`}>
                        Comenzar
                    </Link>
                </header>

                <main className={`MainPage__main`}>
                    <div className={`MainPage__future`}>
                        <div className={`MainPage__futureLeft`}>
                            <h1 className={`MainPage__title`}>Prepárate para tu <strong className={`MainPage__title MainPage__title--bold`}>Futuro</strong></h1>
                            <span className={`MainPage__description`}>
                                Accede a miles de examenes reales de residencia médica.
                            </span>
                        </div>

                        <div className={`MainPage__futureRight`}>
                            <MCImage
                                className={`MainPage__image`}
                                src={`/img/main-future.webp`}
                                alt={`Android App image`}
                            />
                        </div>
                    </div>

                    <div className={`MainPage__practice`}>
                        <div className={`MainPage__practiceLeft`}>
                            <h1 className={`MainPage__title`}>Practica <strong className={`MainPage__title MainPage__title--bold`}>sin limites</strong></h1>
                            <span className={`MainPage__description`}>
                                Resuelve los examenes, revisa en qué debes mejorar y toma el tiempo que resolviste el examen
                            </span>
                        </div>
                        <div className={`MainPage__practiceRight`}>
                            <MCImage
                                className={`MainPage__image`}
                                src={`/img/main-practice.webp`}
                                alt={`Android App image`}
                            />
                        </div>
                    </div>

                    <div className={`MainPage__comment`}>
                        <div className={`MainPage__commentLeft`}>
                            <h1 className={`MainPage__title MainPage__title--bold`}>Has comentarios</h1>
                            <span className={`MainPage__description`}>
                                Podes comentar sobre una pregunta especifca y resolver dudas viendo los comentarios de los demas
                            </span>
                        </div>
                        <div className={`MainPage__commentRight`}>
                            {/*TODO: Pedir imagen con comentarios bien armada como las del playstore */}
                            <MCImage
                                className={`MainPage__image`}
                                src={`/img/main-practice.webp`}
                                alt={`Android App image`}
                            />
                        </div>
                    </div>

                    <div className={`MainPage__start`}>
                        <h1 className={`MainPage__title MainPage__title--bold`}>Empieza ahora mismo</h1>
                        <span className={`MainPage__description`}>MedicalChoice es completamente gratis. <strong className={`MainPage__title--bold`}>¡empieza a utilizarlo ya mismo!</strong></span>
                        <div className={`MainPage__startContainer`}>
                            <Link to={`/app`} className={`MainPage__startButton`}>
                                Comenzar
                            </Link>
                            <a
                                className={`MainPage__google`}
                                href={`https://play.google.com/store/apps/details?id=com.wadiconsulting.residentevilapp&hl=es_AR`}>
                                <MCImage
                                    className={`MainPage__googleImage`}
                                    src={`/img/main-google-play.png`}
                                    alt={`Google play availability`}
                                />
                            </a>
                        </div>
                    </div>

                </main>
                <footer className={`MainPage__footer`}>
                    <div className={`MainPage__footerMain`}>
                        <div className={`MainPage__footerSection MainPage__footerSection--logo`}>
                            <MCImage className={`MainPage__logo`} src={`/logo.svg`} alt={`logo`} />
                            <span className={`MainPage__brand`}>Medical Choice</span>
                        </div>
                        <div className={`MainPage__footerLinks`}>
                            <div className={`MainPage__footerSection`}>
                                <a href={`#`} className={`MainPage__footerSectionItem`}>Terminos y condiciones</a>
                            </div>
                            <div className={`MainPage__footerSection`}>
                                <a href={`#`} className={`MainPage__footerSectionItem`}>Google PlayStore</a>
                            </div>
                            <div className={`MainPage__footerSection`}>
                                <a href={`#`} className={`MainPage__footerSectionItem`}>Contacto</a>
                            </div>
                        </div>
                    </div>
                    <div className={`MainPage__footerSection MainPage__footerSection--copyright`}>
                        © Medical Choice 2020
                    </div>
                </footer>
            </div>
        )
    }
}


export default MainPage;