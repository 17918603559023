import React from "react";
import "./HomeExamResult.css";
import {
    formatDuration,
    getCorrectQuestions,
    getFormattedDuration,
    getIncorrectQuestions,
    getUnansweredQuestions
} from "../../../helpers";

const DATE_FORMAT = new Intl.DateTimeFormat('es', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'})
const DURATION_FORMAT = new Intl.DateTimeFormat('es', {hour: '2-digit', minute: '2-digit', second: '2-digit'})

class HomeExamResult extends React.Component {
    constructor(props) {
        super(props);
    }

    formatStartTime(startTime) {
        const [{ value: day },,{ value: month },,{ value: year },,{ value: hour },,{ value: minute },,{ value: second }] = DATE_FORMAT.formatToParts(new Date(startTime))

        return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    }

    render() {
        const startTime = this.formatStartTime(this.props.exam.startTime);
        const duration = getFormattedDuration(this.props.exam.endTime, this.props.exam.startTime)
        const correct = getCorrectQuestions(this.props.exam.questions)
        const incorrect = getIncorrectQuestions(this.props.exam.questions)
        const unanswered = getUnansweredQuestions(this.props.exam.questions).length

        return (
            <div className={`HomeExamResult card white hoverable`}>
                <div className={`HomeExamResult-container card-content`}>
                    <div className={`HomeExamResult-score`}>
                        <div className={`HomeExamResult-bullet HomeExamResult-correct`}>
                            <span className={`HomeExamResult-bulletText`}>{correct}</span>
                            <span className="circle p-5 display-inline-block green m-5"/>
                        </div>

                        <div className={`HomeExamResult-bullet HomeExamResult-incorrect`}>
                            <span>{incorrect}</span>
                            <span className="circle p-5 display-inline-block red m-5"/>
                        </div>

                        <div className={`HomeExamResult-bullet HomeExamResult-unanswered`}>
                            <span>{unanswered}</span>
                            <span className="circle p-5 display-inline-block yellow m-5"/>
                        </div>

                    </div>
                    <div className={`HomeExamResult-summary`}>
                        <span className={`HomeExamResult-title`}>{this.props.exam.title}</span>
                        <span className={`HomeExamResult-location`}>{this.props.exam.campus_name}</span>
                        <span className={`HomeExamResult-startDate`}>Comenzado: {startTime}</span>
                        <span className={`HomeExamResult-duration`}>Duracion: {duration}</span>
                    </div>
                    <div className={`HomeExamResult-status`}>
                        <img className={`p-0 m-0 valign-middle`}
                             src={`/lock-close-icon.svg`}
                             height={24}
                             width={24}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeExamResult;