import React from 'react';
import PropTypes from 'prop-types';
import "./MCImage.scss";

function MCImage(props) {
    return (
        <figure className={`${props.className} MCImage`}>
            <img
                className={_className(props)}
                src={props.src}
                alt={props.alt}
            />
        </figure>
    );
}

function _className(props) {
    const classNameArray = [`MCImage__image`];

    if (props.fit) classNameArray.push([`MCImage__image--${props.fit}`]);

    return classNameArray.join(' ');
}

MCImage.defaultProps = {
    className: '',
}

MCImage.propTypes = {
    className: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.string,
    fit: PropTypes.oneOf(["cover", "contain"]),
};


export default MCImage;