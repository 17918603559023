import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-materialize";
import "./FeatureNotImplementedModal.css"

class FeatureNotImplementedModal extends React.Component {

    render() {
        return (
            <Modal
                className={`FeatureNotImplementedModal`}
                bottomSheet={false}
                fixedFooter={false}
                open={this.props.show}
                actions={null}
                modalOptions={{
                    complete: this.props.onDismiss
                }}
            >
                <div className={`FeatureNotImplementedModal-container`}>
                    <div className={`FeatureNotImplementedModal-header`}>
                        <div className={`FeatureNotImplementedModal-title`}>Funcionalidad aun no implementada</div>
                    </div>
                    <div className={`FeatureNotImplementedModal-content`}>
                        <div className={`FeatureNotImplementedModal-description`}>{this.props.description}</div>
                    </div>
                </div>
            </Modal>
        );
    }
}

FeatureNotImplementedModal.defaultProps = {
    description: `Estamos trabajando para agregar esta funcionalidad en un futuro. Disculpe las molestias ocacionadas.`
}

FeatureNotImplementedModal.propTypes = {
    show: PropTypes.bool,
    onDismiss: PropTypes.func,
    description: PropTypes.string
}

export default FeatureNotImplementedModal;