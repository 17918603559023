export class LocalStorage {
    /**
     *
     * @param {String} key
     * @return {null|Object}
     */
    static getItem(key) {
        const value = localStorage.getItem(LocalStorage.getKey(key));
        return JSON.parse(value);
    }

    /**
     *
     * @param {String} key
     * @param {Object} value
     * @return {null|void}
     */
    static setItem(key, value) {
        return localStorage.setItem(LocalStorage.getKey(key), JSON.stringify(value));
    }

    /**
     *
     * @param {String} key
     * @return {String|null}
     */
    static getKey(key) {
        return `${window.location.hostname}-${key}`;
    }

    /**
     *
     * @param {String }key
     * @return {String|null}
     */
    static removeItem(key) {
        const value = LocalStorage.getItem(key);
        localStorage.removeItem(LocalStorage.getKey(key));

        return value;
    }
}

function getAnsweredQuestions(questions) {
    return questions.filter((question) => (question.answered))
}

function getUnansweredQuestions(questions) {
    return questions.filter((question) => (!question.answered))
}

function getCorrectQuestions(questions) {
    return getAnsweredQuestions(questions)
        .map((question) => (question.answers.filter((answer) => (answer.selected)).every((answer) => (answer.correct))))
        .filter((value) => (value === true)).length;
}

function getIncorrectQuestions(questions) {
    return getAnsweredQuestions(questions)
        .map((question) => (question.answers.filter((answer) => (answer.selected)).every((answer) => (answer.correct))))
        .filter((value) => (value === false)).length;
}

function formatDuration(duration) {
    let hours   = Math.floor(duration / 3600);
    let minutes = Math.floor((duration - (hours * 3600)) / 60);
    let seconds = Math.floor(duration - (hours * 3600) - (minutes * 60));

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}



    return hours+':'+minutes+':'+seconds;
}

function getFormattedDuration(endTime, startTime) {
    const duration = (new Date(endTime) - new Date(startTime))/1000;

    return formatDuration(duration)
}


export default LocalStorage;
export {
    getAnsweredQuestions,
    getUnansweredQuestions,
    getCorrectQuestions,
    getIncorrectQuestions,
    formatDuration,
    getFormattedDuration
};