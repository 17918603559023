import React from 'react';
import './ExamRow.css';

class ExamRow extends React.Component {
  render(){
    return (
      <div
        className="ExamRow card white hoverable ExamRow mt-5 mb-5"
        onClick={this.props.onClick}>
        <div className="ExamRow-container card-content">
          <span className="card-title teal-text">{this.props.name}</span>
          <p className="text-color-secondary">{this.props.amount} Exámenes</p>
        </div>
      </div>
    );
  }
}

export default ExamRow;