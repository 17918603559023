import React from 'react';
import {Tabs, Tab} from 'react-materialize';
import './Header.css';
import LocalStorage from "../../../helpers";

const SAVED_EXAMS = `SAVED_EXAMS`;

class Header extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <div className={`Header teal z-depth-1 ${this.props.className}`}>
        <nav className="transparent z-depth-0 hide-on-med-and-down">
          <div className="nav-wrapper Header-wrapper">
            <a href="/app" className="brand-logo Header-logo ml-10">
                <img className={`p-0 m-0 valign-middle`}
                     src={`/logo.svg`}
                     height={48}
                     width={48}/>
                <span className={`Header-logoText white-text`}>Medical Choice</span>
            </a>
            <ul className="right mr-10">
              <li><span className="disabled">Iniciar Sesion</span></li>
              <li><span className="disabled bold">Registrarse</span></li>
            </ul>
          </div>
        </nav>
        <div className={`Header-container`}>
          {
            this.props.children
          }
        </div>
      </div>
    )
  }
}

class _Main extends React.Component{
    constructor(props) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(event) {
        const tabs = Array.from(document.querySelectorAll(`.Header-tab a`));

        const [activeTab] =  tabs.filter(x => x.className === `active`);

        const indexOfActive = tabs.indexOf(activeTab);

        if (indexOfActive === 0) {
            this.props.handleTabChange(`practice`)
        }

        if(indexOfActive === 1) {
            this.props.handleTabChange(`results`)
        }
    }

    render() {
        const shouldBeDisabled = Object.entries((LocalStorage.getItem(SAVED_EXAMS) || {})).length === 0

        return (
            <Header {...this.props}>
              <Tabs className="Header-tabs transparent" onChange={this.handleTabChange}>
                <Tab className="Header-tab Header-tab-practice" title="Practicar" data-name={`practice`} dataName={`practice`}/>
                <Tab className={`Header-tab Header-tab-results ${shouldBeDisabled ? `Header-tab--disabled`: ''}`} title="Mis Resultados" data-name={`results`} dataName={`results`} disabled={shouldBeDisabled}/>
                <Tab className="Header-tab Header-tab--disabled" title="Mis Comentarios" disabled/>
              </Tabs>
            </Header>
        )
      }
}

class _Toolbar extends React.Component{
  render() {
    return (
        <Header {...this.props}>
          <div className="Toolbar nav-wrapper">
            <button className={`Toolbar-button`} onClick={this.props.handleBackButtonClicked}>
              <i className={`Toolbar-icon material-icons white-text`}>arrow_back</i>
            </button>

            <div className={`Toolbar-content`}>
              <div className={`Toolbar-title white-text`}>
                {this.props.title}
              </div>

              <div className={`Toolbar-subtitle white-text`}>
                {this.props.subtitle}
              </div>
            </div>

          </div>
        </Header>
    )
  }
}

export default {
  Toolbar: _Toolbar,
  Main: _Main
};