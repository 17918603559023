import React from "react";
import {Col, Row, Button} from "react-materialize";
import {Link} from "react-router-dom";
import "./ExamResults.css";
import {
    getCorrectQuestions, getFormattedDuration,
    getIncorrectQuestions,
    getUnansweredQuestions
} from "../../../../../helpers";

class ExamResults extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="ExamResults">
                <Row className="center-align m-0 p-0">
                    <Col l={12} m={12} s={12}>
                        <span className="text-font-x-large">{this.props.exam.title}</span>
                        <br/>
                        <span className="text-font-medium">{this.props.exam.campus_name}</span>
                    </Col>
                </Row>
                <Row className="valign-wrapper m-0 p-5">
                    <Col l={9} m={9} s={9} className="text-font-medium valign-wrapper">
                        <span className="circle p-5 display-inline-block green m-5"/>
                        Correctas:
                    </Col>
                    <Col l={3} m={3} s={3} className="right-align text-font-medium">{getCorrectQuestions(this.props.exam.questions)}</Col>
                </Row>
                <hr className="bs-1_2-grey mt-0 mb-0 ml-10 mr-10"/>
                <Row className="valign-wrapper m-0 p-5">
                    <Col l={9} m={9} s={9} className="text-font-medium valign-wrapper">
                        <span className="circle p-5 display-inline-block red m-5"/>Incorrectas:
                    </Col>
                    <Col l={3} m={3} s={3} className="right-align text-font-medium">{getIncorrectQuestions(this.props.exam.questions)}</Col>
                </Row>
                <hr className="bs-1_2-grey mt-0 mb-0 ml-10 mr-10"/>
                <Row className="valign-wrapper m-0 p-5">
                    <Col l={9} m={9} s={9} className="text-font-medium valign-wrapper">
                        <span className="circle p-5 display-inline-block grey m-5"/>
                        Sin responder:
                    </Col>
                    <Col l={3} m={3} s={3} className="right-align text-font-medium">{getUnansweredQuestions(this.props.exam.questions).length}</Col>
                </Row>
                <hr className="bs-1_2-grey mt-0 mb-0 ml-10 mr-10"/>
                <div className="ExamResults-durationWrapper valign-wrapper m-0 p-5">
                    <span>Duracion: {getFormattedDuration(this.props.exam.endTime, this.props.exam.startTime) }</span>
                </div>
                <Row className="m-5 p-0">
                    <Col l={12} m={12} s={12} className="center-align">
                        <Link to={`/app`}>
                            <Button
                                className="blue btn-small text-font-smaller"
                                modal={"close"}
                                waves="light" >Nuevo Examen</Button>
                        </Link>
                    </Col>
                </Row>
                <Row className="m-5 p-0">
                    <Col l={12} m={12} s={12} className="center-align">
                        <Button
                            className="orange-text btn-small btn-flat text-font-smaller"
                            waves="light"
                            modal="close"
                            onClick={this.props.handleReviewExamClicked}
                        >
                            Revisar este examen
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

}

export default ExamResults;