import React from 'react';
import PropTypes from 'prop-types';
import './PageWrapper.css';
import {Footer} from "react-materialize";
import Header from "../../home/header/Header";

class PageWrapper extends React.Component {
    render() {
        return (
            <div className="PageWrapper grey lighten-4 ">

                {
                    (this.props.title == null && this.props.subtitle == null && this.props.handleBackButtonClicked == null) ? (
                        <Header.Main className="PageWrapper-header PageWrapper-header--head" handleTabChange={this.props.handleTabChange}/>
                    ) : (
                        <Header.Toolbar
                            className="PageWrapper-header PageWrapper-header--toolbar"
                            handleBackButtonClicked={this.props.handleBackButtonClicked}
                            title={this.props.title}
                            subtitle={this.props.subtitle}
                        />
                    )
                }


                <div className={`PageWrapper-container`}>
                    {this.props.children}
                </div>


                <Footer copyrights="Propiedad de Marcelo Lopez Luksenberg"
                        className='teal white-text PageWrapper__footer'
                        links={
                            <ul>
                                <li><a className="grey-text text-lighten-3" href="#!">Terminos y condiciones</a></li>
                                <li><a className="grey-text text-lighten-3" href="#!">Google Playstore</a></li>
                                <li><a className="grey-text text-lighten-3" href="#!">Contacto</a></li>
                            </ul>
                        }>
                    <h5 className="white-text">Medical Choices</h5>
                    <p className="grey-text text-lighten-4">Con Medical Choice puedes practicar miles de examenes de
                        residencia en un solo lugar</p>
                </Footer>
            </div>
        );
    }
}

PageWrapper.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    handleBackButtonClicked: PropTypes.func
}

export default PageWrapper;