import axios from 'axios'
import Firebase from "../firebase";

export const HttpError = {
    SERVER_ERROR: 1,
    NOT_INTERNET_ERROR: 2
};

let instance = null;

class ExamAPI {
    constructor(opts) {

        this.axiosInstance = axios.create({
            baseURL: `${opts.endpoint}/medicalchoice-api/mobile`, //TODO: Tomar esto desde un environment por ambiente
            mode: 'cors',
            headers: {
                'X-Api-Key': '2773ac6a-5ff1-43df-9d1c-c8cde26da909', //TODO: Ver de sacar la APIKey y ver como ofuscarla
                'X-Content-Type-Options': 'nosniff',
                'Content-Type': 'application/json;charset=utf-8',
            },
        });
    }

    getExams(onSuccessCallback, onErrorCallback) {
        this.axiosInstance
            .get("/categories")
            .then(onSuccessCallback)
            .catch(this._onError(onErrorCallback))
    }

    getExam(examId, type, onSuccessCallback, onErrorCallback) {
        this.axiosInstance
            .get(`/exam/${examId}`, {params: {type}})
            .then(onSuccessCallback)
            .catch(this._onError(onErrorCallback))
    }

    _onError(onErrorCallback) {
        return error => {
            if (error.response == null) {
                onErrorCallback(HttpError.NOT_INTERNET_ERROR);
            } else {
                //TODO: Handle other error types
                switch (error.response.status) {
                    case 404:
                        onErrorCallback(HttpError.SERVER_ERROR);
                        break;
                    case 500:
                        onErrorCallback(HttpError.SERVER_ERROR);
                        break;
                    default:
                        onErrorCallback(HttpError.SERVER_ERROR);
                }
            }
        };
    }

    static getInstance() {
        return instance;
    }

    static async createAndInitialize() {
        if (instance == null) {
            const firebase = Firebase.getInstance();
            let endpoint = await firebase.getRemoteString('ENDPOINT_URL');
            if (!endpoint) {
                endpoint = "https://api.medicalchoice.com.ar"
            }
            console.log("Endpoint URL: " + endpoint)
            instance = new ExamAPI({endpoint});
        }
        return instance;
    }
}

export default ExamAPI