import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-materialize";
import "./TopicExamInfoModal.css"

class TopicExamInfoModal extends React.Component {

    render() {

        return (
            <Modal
                className={`TopicExamInfoModal`}
                bottomSheet={false}
                fixedFooter={false}
                open={this.props.show}
                actions={null}
                modalOptions={{
                    complete: this.props.onDismiss
                }}
            >
                <div className={`TopicExamInfoModal-container`}>
                    <div className={`TopicExamInfoModal-header`}>
                        <div className={`TopicExamInfoModal-headerTitle`}>Informacion de Pregunta</div>
                    </div>
                    <div className={`TopicExamInfoModal-content`}>
                        <div className={`TopicExamInfoModal-title`}>
                            <span className={`TopicExamInfoModal-label`}>Titulo: </span>
                            {this.props.examInformation.title}
                        </div>
                        <div className={`TopicExamInfoModal-category`}>
                            <span className={`TopicExamInfoModal-label`}>Cagegoria: </span>
                            {this.props.examInformation.campus_name}
                        </div>
                        <div className={`TopicExamInfoModal-date`}>
                            <span className={`TopicExamInfoModal-label`}>Fecha: </span>
                            {this.props.examInformation.date}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

TopicExamInfoModal.defaultProps = {
    description: `Estamos trabajando para agregar esta funcionalidad en un futuro. Disculpe las molestias ocacionadas.`
}

TopicExamInfoModal.propTypes = {
    show: PropTypes.bool,
    onDismiss: PropTypes.func,
    description: PropTypes.string
}

export default TopicExamInfoModal;