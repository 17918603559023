import "./QuestionFlow.css"
import React from "react";
import {Col, Row} from "react-materialize";


class QuestionFlow extends React.Component {
    constructor(props) {
        super(props)
    }

    _isShowResultButtonEnabled() {
        const questions = this.props.questions;
        return this.props.examFinished || questions.filter((question) => (question.answered)).length > questions.length * 0.01
    }

    _getShowResultClassNameValue() {
        let baseClassName = "QuestionFlow-show-results valign-middle waves-effect btn-flat orange-text darken-4 p-0";
        if (!this._isShowResultButtonEnabled() ) baseClassName = baseClassName + " disabled";
        return baseClassName;
    }

    _getChevronLeftClassNameValue() {
        let baseClassName = "medium material-icons valign-middle waves-effect ";
        if (this.props.currentQuestionNumber !== 0) baseClassName = baseClassName + " orange-text darken-4";
        else baseClassName = baseClassName + "text-color-secondary disabled";
        return baseClassName
    }

    _getChevronRightClassNameValue() {
        let baseClassName = "medium material-icons valign-middle waves-effect ";
        if (this.props.currentQuestionNumber !== this.props.questions.length - 1) baseClassName = baseClassName + " orange-text darken-4";
        else baseClassName = baseClassName + "text-color-secondary disabled";
        return baseClassName
    }

    render() {
        return (
            <div className={`QuestionFlow grey lighten-4`}>
                <i
                    className={this._getChevronLeftClassNameValue()}
                    onClick={() => (this.props.onStepperSelected(this.props.currentQuestionNumber-1))}>
                    chevron_left
                </i>
                <span className={this._getShowResultClassNameValue(this.props.examFinished)} onClick={()=>this.props.onShowResultSelected()}>Ver resultados</span>
                <i
                    className={this._getChevronRightClassNameValue()}
                    onClick={() => (this.props.onStepperSelected(this.props.currentQuestionNumber+1))}>
                    chevron_right
                </i>
            </div>
        )
    }
}

export default QuestionFlow;