import "./QuestionNumberPaginarion.css"
import React from "react";
import QuestionNumber from "./QuestionNumber/QuestionNumber";

class QuestionNumberPagination extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ul className="QuestionNumberPagination white p-10 m-0">
                {this.props.questions.map((question, index) => (
                    <QuestionNumber
                        key={question.id}
                        height={40}
                        width={40}
                        number={index}
                        question={question}
                        isQuestionSelected={this.props.currentQuestionNumber === index}
                        onQuestionNumberSelected={this.props.onQuestionNumberSelected}
                        examFinished={this.props.examFinished}
                        fromSwipe={this.props.fromSwipe}
                    />
                ))}
            </ul>
        )
    }
}

export default QuestionNumberPagination;