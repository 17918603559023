import React from "react";
import './ServerError.css';


class ServerError extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="ServerError center-align" >
                <img
                    src="/satellite.svg"
                    alt="Server Error"
                    height={120}
                    width={120}
                />
                {this.props.message && <p className="text-font-medium text-color-primary">{this.props.message}</p>}
                <a className="waves-effect waves-dark btn orange" onClick={this.props.onRetry}>Reintentar</a>
            </div>
        );
    }

}

export default ServerError;