import React from 'react';
import {Link} from "react-router-dom";
import './ExamContent.css'
import PageWrapper from "../../../../common/PageWrapper/PageWrapper";
import Firebase from "../../../../../firebase";

class ExamContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: undefined
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {categoryId} = props.match.params;
        const categories = props.categories.filter((category) => (category.id === categoryId));
        //TODO: Check if categories is null or empty and throw some exception
        return {
            category: categories[0]
        }
    }


    componentDidUpdate() {
        Firebase.getInstance().trackPageView('/app/exam-content');
    }


    render() {
        return (
            <PageWrapper title={this.state.category.name} subtitle={`Cantidad de Examenes: ${this.state.category.exams.length}`} handleBackButtonClicked={() => {window.history.back()}}>
                <div className={`ExamContent`}>
                    {/*<div className={`ExamContent__adContainer ExamContent__adContainer--left`}>*/}

                    {/*</div>*/}
                    <ul className={`ExamContent ExamContent__main collection with-header`}>
                        {
                            this.state.category.exams.map((exam) => (
                                <Link className="collection-item" key={exam.id} to={`/app/exams/${exam.id}/questions?type=${exam.type}`}>
                                    <img className="secondary-content" src="/arrow-right.svg" height={24} width={24}/>
                                    <div>
                                        <div className="text-font-medium text-color-primary">{exam.title}</div>
                                        <div className="text-font-smaller text-color-secondary">Preguntas: {exam.question_size}</div>
                                    </div>
                                </Link>
                            ))
                        }
                    </ul>
                    {/*<div className={`ExamContent__adContainer`}>*/}

                    {/*</div>*/}
                </div>
            </PageWrapper>
        )
    }
}

export default ExamContent;