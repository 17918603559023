import './ExamCategories.css';
import React from 'react';
import {Col, Row} from 'react-materialize';
import ExamRowContainer from './ExamRow/ExamRowContainer';
import PageWrapper from "../../common/PageWrapper/PageWrapper";
import {LocalStorage} from "../../../helpers";
import {Link} from "react-router-dom";
import HomeExamResult from "../../common/HomeExamResult/HomeExamResult";
import Firebase from "../../../firebase";

const SAVED_EXAMS = `SAVED_EXAMS`

class ExamCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: `practice`
        }
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleCardClick = this.handleCardClick.bind(this);
    }

    handleTabChange(tabName) {
        this.setState({tab: tabName});
    }

    handleCardClick() {

    }

    componentDidMount() {
        Firebase.getInstance().trackPageView('/app');
    }

    render() {
        return (
            <PageWrapper handleTabChange={this.handleTabChange}>
                <Row className={`ExamCategories Content-row`}>
                    {/*<div className={`ExamCategories__adContainer ExamCategories__adContainer--left`}>*/}

                    {/*</div>*/}

                    <main className={`ExamCategories__main`}>
                        {
                            this.state.tab === `practice` && this.props.examCategories && this.props.examCategories.map((category) => (
                                <Col l={4} m={6} s={12} key={category.name}>
                                    <ExamRowContainer
                                        id={category.id}
                                        name={category.name}
                                        amount={category.exams.length}
                                        exams={category.exams}
                                        categoryName={category.name}
                                    />
                                </Col>
                            ))
                        }

                        {
                            this.state.tab === `results` && (Object.entries(LocalStorage.getItem(SAVED_EXAMS) || {})).map(([k,v]) => (
                                <Col l={4} m={6} s={12} key={k}>
                                    <Link to={`/app/exams/${v.id}/questions?type=${v.type}&resultId=${k}`}>
                                        <HomeExamResult exam={v}/>
                                    </Link>
                                </Col>
                            ))
                        }
                    </main>

                    {/*<div className={`ExamCategories__adContainer`}>*/}

                    {/*</div>*/}
                </Row>
            </PageWrapper>
        )
    }
}


export default ExamCategories;