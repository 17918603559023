import React from 'react';
import ExamRow from './ExamRow';
import {Link} from "react-router-dom";

class ExamRowContainer extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Link to={`/app/categories/exams/${this.props.id}`}>
                <ExamRow
                    name={this.props.name}
                    amount={this.props.amount}/>
            </Link>
           )
    }
}

export default ExamRowContainer;